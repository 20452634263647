.hamburger {
  border: 0;
  box-shadow: 0px 0px 25px 0px rgba(66, 68, 90, 1);
  background-color: #73a895;
  border-radius: 50%;
  color: #fff;
  height: 60px;
  font-size: 20px;
  outline: none;
  position: fixed;
  right: 30px;
  width: 60px;
  top: 30px;
  transition: 0.5s;
  z-index: 999;

  .icon {
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 30px;
    top: 30px;
    left: 15px;
    background-color: #fff;

    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 30px;
      height: 3px;
      background-color: #fff;
      content: '';
      top: -10px;
      left: 0;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 30px;
      height: 3px;
      background-color: #fff;
      content: '';
      top: 10px;
      left: 0;
    }
  }
}
.open {
  .icon {
    background: transparent;

    &:before {
      transform: rotateZ(45deg) scaleX(1.25) translate(5px, 7px);
    }

    &:after {
      transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -7px);
    }
  }
}

@media (min-width: 992px) {
  .hamburger {
    display: none;
  }
}

#nav-mobile {
  background-color: rgba($color: #000000, $alpha: 0.95);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 120px 50px 25px;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 998;

  .nav-list {
    list-style: none;
    padding: 0;

    .nav-item {
      a {
        color: #73a895 !important;
        font-family: 'Barlow Medium';
        font-size: 32px;
        text-transform: uppercase;
      }
    }
  }
  img {
    height: 200px;
    margin: 0 auto 50px;
    max-width: 200px;
    width: 100%;
  }
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(100%);
}

@media (min-width: 992px) {
  #nav-mobile {
    display: none;
  }
}

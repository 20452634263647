header {
  background-image: url('../../static/assets/images/header-banner.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 30px 30px;
  height: 100vh;
  width: 100%;
  position: relative;
}

@media (min-width: 992px) {
  header {
    height: 500px;
    margin-bottom: 200px;
  }
}

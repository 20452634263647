.icon {
  text-align: center;

  &-primary {
    background: #ebf8cd;
  }
  &-secondary {
    background: #547352;
  }
  figure {
    align-items: center;
    border-radius: 50%;
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.54);
    display: flex;
    margin: 0 auto;
    justify-content: center;
    height: 150px;
    width: 150px;

    img {
      transition: 0.5s;
    }

    #about-icon {
      height: 100px;
      width: 74px;
    }
    #reservation-icon {
      height: 100px;
      width: 100px;
    }
    #contact-icon {
      height: 128px;
      width: 120px;
    }
    #gallery-icon {
      height: 118px;
      width: 110px;
    }
    #menu-icon {
      height: 140px;
      width: 130px;
    }
    #news-icon {
      height: 70px;
      width: 90px;
    }
  }
  figcaption {
    font-family: 'Barlow';
    font-size: 28px;
    line-height: 48px;
    padding-top: 20px;
    text-transform: uppercase;
    transition: 0.5s;
  }
  &:hover {
    figure img {
      transform: scale(0.9);
    }
    figcaption {
      transform: scale(1.1);
    }
  }
}

.nomatch {
  background-image: url('../../static/assets/images/header-banner.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
  width: 100vw;
  height: 100vh;

  .box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba($color: #000000, $alpha: 0.9);

    img {
      width: 200px;
      height: 200px;
    }
    h1 {
      color: #718948;
      font-family: 'Barlow';
      font-size: 96px;
      line-height: 144px;
      text-align: center;
    }
  }
}

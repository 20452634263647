.app {
  margin: 0 auto;
  max-width: 1920px;
  overflow-x: hidden;
  width: 100%;

  main {
    padding-top: 100px;
  }
}

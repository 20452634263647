.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    padding: 0 15px;
    height: 330px;
    max-width: 100%;
    width: 360px;
    transition: 0.5s;

    &:hover {
      transform: scale(0.95);
    }
  }
}

@media (min-width: 768px) {
  .logo {
    img {
      max-width: 600px;
    }
  }
}

@media (min-width: 992px) {
  .logo {
    justify-content: left;
    padding-left: 100px;
    img {
      max-width: 400px;
    }
  }
}

@media (min-width: 1600px) {
  .logo {
    padding-left: 200px;

    img {
      height: 370px;
      width: 400px;
    }
  }
}

nav {
  #nav-desktop {
    display: none;
  }
}

@media (min-width: 992px) {
  nav {
    margin-top: -70px;
    #nav-desktop {
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 0 60px;
      width: 100%;
    }
  }
}
@media (min-width: 1600px) {
  nav {
    #nav-desktop {
      max-width: 1500px;
      margin: 0 auto;
    }
  }
}

.reservation-modal {
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  left: 0;
  position: fixed;
  right: 0;
  visibility: hidden;
  top: 0;
  transition: .5s;
  z-index: 999;
  padding: 0 15px;

  .box {
    background-image: url('../../static/assets/images/header-banner.webp');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 75px 30px;
    border-radius: 20px;

    h2 {
      color: #EBF8CD;
      font-family: 'Barlow Medium';
      background-color: #547352;
      font-size: 50px;
      line-height: 60px;
      padding: 15px 25px;
      border-radius: 15px;
      text-align: center;
      margin-bottom: 30px;
    }
    ul {
      list-style: none;
      padding: 0;

      li {
        text-align: center;
        background-color: #EBF8CD;
        margin: 0 10px;
        border-radius: 15px;
        padding: 15px 25px;
        margin-bottom: 10px;
        transition: .5s;

        a {
          color: #547352;
          display: block;
          font-family: 'Barlow';
          font-size: 40px;
          line-height: 50px;
          width: 100%;
          height: 100%;
        }
        &:hover {
          background-color: rgba($color: #b2c782, $alpha: 1.0);
        }
      }
    }
  
    #close-btn {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 50px;
      height: 50px;
    }
  }
}

.open {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 992px) {
  .reservation-modal {
    display: flex;
  
    .box {
      padding: 75px 100px;
  
      ul {
        display: flex;
      }
    }
  }
}
* {
  margin: 0;
  padding: 0;
  font-size: 10px;
  box-sizing: border-box;
}

a {
  color: #000 !important;
  text-decoration: none !important;
  text-transform: 0.5s;
}

@font-face {
  font-family: 'Barlow';
  src: url('../src/static/fonts/Barlow.woff2');
  src: url('../src/static/fonts/Barlow.woff2') format('woff2'),
    url('../src/static/fonts/Barlow.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Medium';
  src: url('../src/static/fonts/Barlow-Medium.woff2');
  src: url('../src/static/fonts/Barlow-Medium.woff2') format('woff2'),
    url('../src/static/fonts/Barlow-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

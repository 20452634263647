footer {
  img {
    height: 20px;
    width: 1200px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  footer {
    img {
      width: 1920px;
      height: 80px;
    }
  }
}

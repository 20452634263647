$mobile-background: #73a895;
$mobile-color: #ebf8cd;

.language-list {
  display: none;
}
.list-open {
  display: block;
  left: 30px;
  position: absolute;
  top: 30px;

  .dropdown {
    button {
      color: $mobile-color;
      background-color: $mobile-background;
      border-radius: 10px;
      font-family: 'Barlow Medium';
      font-size: 20px;
      width: 80px;
    }
    ul {
      min-width: auto;
      width: 80px;

      li {
        background-color: $mobile-background;
        a {
          color: #fff !important;
          font-family: 'Barlow';
          font-size: 16px;
          transition: 0.5s;
        }
      }
      .active {
        background-color: $mobile-color;

        a {
          color: $mobile-background !important;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .language-list {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    .dropdown {
      button {
        color: $mobile-color;
        background-color: $mobile-background;
        border-radius: 10px;
        font-family: 'Barlow Medium';
        font-size: 20px;
        width: 80px;
      }
      ul {
        min-width: auto;
        width: 80px;

        li {
          background-color: $mobile-background;
          a {
            color: #73a895;
            font-family: 'Barlow';
            font-size: 16px;
            transition: 0.5s;

            &:hover {
              background-color: #ebf8cd;
            }
          }
        }
        .active {
          background-color: #ebf8cd;
        }
      }
    }
  }
}
